import {TarafSifatiEntity} from "@/entity/TarafSifatiEntity";

export default class BorcluBilgileri {
    isim!: string;
    ad!: string;
    soyad!: string;
    cinsiyet!: string;
    adres_bilgisi!: string;
    adres_turu!: string;
    il!: string;
    ilce!: string;
    kurum!: string;
    cepTelefon!: number;
}