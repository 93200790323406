import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import {TakipMahiyetiEntity} from "@/entity/TakipMahiyetiEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {TakipYoluEntity} from "@/entity/TakipYoluEntity";

export default class DosyaBilgileri {
    takip_tipi!: number;
    takip_alt_tipi !: string;
    takip_yolu !: string;
    takip_tarihi!: string;
    tekil_alan !: number;
    takip_mahiyeti!: string;
    icra_dairesi!: string;
    esas_no!: string;

}