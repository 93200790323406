import DosyaBilgileri from "@/classes/topluTakip/DosyaBilgileri";
import AlacakliBilgileri from "@/classes/topluTakip/AlacakliBilgileri";
import BorcluBilgileri from "@/classes/topluTakip/BorcluBilgileri";
import AlacakKalemiBilgileri from "@/classes/topluTakip/AlacakKalemiBilgileri";

export default class TopluKartEkleme {
    dosyaBilgileri !: DosyaBilgileri;
    alacakliBilgileri : Array<AlacakliBilgileri>= [];
    borcluBilgileri: Array<BorcluBilgileri> = [];
    alacakKalemiBilgileri : Array<AlacakKalemiBilgileri> = [];
}