import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {AlacakBelgeTuru} from "@/enum/AlacakBelgeTuru";
import {NoterEntity} from "@/entity/NoterEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {MahkemeEntity} from "@/entity/MahkemeEntity";
import {TasinirEntity} from "@/entity/AlacakBelgeler/TasinirEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {IpotekEntity} from "@/entity/Haciz/IpotekEntity";

export default class AlacakKalemiBilgileri {
    belge_turu!:string;
    tutar!:number;
    vade_tarih!:string;
    faiz_baslangic_tarih!:string;
    alacak_aciklamasi!:string;
    takip_oncesi_faiz_isle!:any;
    takip_sonrasi_faiz_isle!:any;
    takip_oncesi_faiz_turu!:string;
    takip_sonrasi_faiz_turu!:string;
    elle_faiz_mi!:boolean;
    elle_faiz_tutar_mi!:boolean;
    elle_faiz_tutari!:number|null;
    donem_baslama_tarihi!:string;
    elle_faiz_tutari_yillik!:number|null;


}